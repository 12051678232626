.packingDescription{
    color:var(--info) !important;
    font-weight: 600;
}

.check-omni-disabled>label{
    color: var(--dark) !important;
}

.check-omni-disabled>div>svg{
    background-color: #fff;
    color: var(--info);
}

.check-omni-disabled-div{
    padding-left: 40px;
    padding-top: 15px;
    font-size: 15px !important;
}

.long-card{
    width: calc(100% -30px);
    margin-left: 12px;
}

.header-cell-itemByBranch{
    font-weight: 600 !important;
}
.cell-bold{
    font-weight: 600 !important;
}

.cell-store{
    color:var(--info) !important;
}

.cell-warehouse{
    color:var(--purple) !important;
}

.cell-stock-empty{
    color:var(--danger) !important;
}

.cell-check-disabled>div{
    border-color: #fff;
}

.cell-check-disabled>div>svg{
    background-color: var(--secondary) !important;
    color: white !important;
}

.restocking-button{
   min-width: auto !important;
}