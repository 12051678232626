.red-indicator > div{
    border-color: var(--pink) !important;
    color: var(--pink) !important;
}


.radio-group-omni > div > span > div{
    border-color: var(--pink) ;
    color: var(--pink) ;
}

