.itemsselector-container-with-filters{
    height:  calc(100vh - 340px) !important;
}

.filter-section{
    border-bottom: 1px solid var(--light);
    padding-top: 14px;
    padding-bottom: 14px;
}

.stack-filter{
    padding-right: 15px;
}

.div-clear-button{
    border-left: 1px solid var(--secondary);
    display: initial;
    padding-left: 10px;
    padding-right: 10px;
}

.filter-svg-button{
    height: 30px !important;
    width: 30px !important;
    margin: none !important;
}
.lf-player-container{
    margin: 0px;
}
.filter-svg-button> svg >g > g >g >path{
    fill: var(--info) !important;
}

.filter-svg-button:hover {
    cursor: pointer;
}

.filter-svg-button-fill {
    height: 30px !important;
    width: 30px !important;
    margin: none !important;
    background-color: var(--info) !important;
}

    .filter-svg-button-fill > svg > g > g > g > path {
        fill: white !important;
    }
    .filter-svg-button-fill > svg > g > g > g > g > path {
        fill: white !important;
    }
    .filter-svg-button-fill:hover {
        cursor: pointer;
    }

@keyframes rebound {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 5px, 0);
    }
}