.button-variety-select{
    border: 2px solid var(--info) !important;
}
.button-variety-select-span{
    color: var(--info);
    padding-left: 15px;
    font-weight: 600;
}

.label-variety-select{
    color: var(--gray);
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


/*DRAWER VARIETY*/
.drawer-variety-card-title{
    font-weight: 600;
    size: 20px;
    padding-left: 15px;
}
.drawer-variety-card-p{
    padding-top: 15px;
}
.drawer-variety-card-text-blue{
    color: var(--info);
    font-weight: 600;
}
.drawer-variety-card-text-red{
    color: var(--danger);
    font-weight: 600;
}
/*FIN DRAWER VARIETY*/