.item-selector{
    display: flex;
}

.stack-itemsselector-container {
    width: calc(100% - 400px);
    background-color: var(--light-hover);
}
.itemsselector-container{
    border-right: 1px solid var(--light);
    height: 100vh;
    width: 400px;
}

.item-selector-list-content{
    display: block;
}

.itemsselector-title{
    font-size: 20px;
    padding-left: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.itemsselector-title-container{
    display: flex;
}

.itemsselector-item-image{
    padding-top: 4px;
}

.itemsselector-title-text{
    padding-left: 18px;
    font-size: 20px;
    font-weight: 600;
}

.itemsselector-search-container{
    border: 1px solid var(--light);
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.itemsselector-search-input{
    border-radius: 4px;   
}

.itemsselector-items-container{
    padding-top: 8px;
    overflow-y: auto;
    height:  calc(100vh - 285px);
}

.itemsselector-items-container-hide{
    display: none;
}

.itemsselector-items-paginate{
    height: 40px;
    border-top: 1px solid var(--light);
    padding-top: 10px;
    padding-right: 20px;
}
.itemsselector-items-paginate-text{
    color:var(--gray);
    
}
.itemsselector-items-paginate-left{
    fill: var(--gray-light) !important;
    margin-left: 10px;
    min-height: 20px;
}
    .itemsselector-items-paginate-left:hover{
        fill: var(--info) !important;
        cursor: pointer;
    }
.itemsselector-items-paginate-right{
    fill: var(--gray-light) !important;
    margin-left: 10px;
}
    .itemsselector-items-paginate-right:hover{
        fill: var(--info) !important;
        cursor: pointer;
    }
.itemsselector-item{
    border:1px solid var(--light);
}

.itemsselector-items-result{
    font-size: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    border-top: 1px solid var(--light);
    width: 100%;
    display: block;
}

.pivot-center-search > div[role="tablist"] {
    padding-left: 25px;
}

button.is-selected[name="Inactivos"]:before {
    background-color: var(--danger);
}
button.is-selected[name="Inactive"]:before {
    background-color: var(--danger);
}
button.is-selected[name="Todos"]:before {
    background-color: var(--dark);
}

button.is-selected[name="All"]:before {
    background-color: var(--dark);
}

.itemsselector-item-no-result {
    padding-left: 32px;
    padding-top: 12px;
    color: var(--secondary);
}


@media (max-width: 1200px) {
    .item-selector-list-content{
        display: contents;
    }
}
@media (max-width:768px) {
    .itemsselector-container{
        width: auto;
    }       
    .stack-itemsselector-container{
        width: 100%;
    }
}
