.catalog-border {
    border-bottom: 1px #e0e0e0 solid;
    height: 44px;
    padding-bottom: 0px;
    padding-top: 0px
}
.catalog {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.padding-firstlevel {
    padding-left: 30px;
}

.padding-secondlevel {
    padding-left: 60px;
}

.selected {
    background-color: #F2F2F2 !important;
}
.text-main {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}
.text-secondary {
    font-weight: 400;
    text-align: left;
    display: block;
}
.text-info {
    color: var(--info) !important;
}
.card-table-max-height {
    max-height: calc(100vh - 310px);
    overflow-y: auto;
}

.card-table-max-height-search {
    max-height: calc(100vh - 360px);
    overflow-y: auto;
}
.selectable {
    cursor: pointer !important;
}
    .selectable > div > label {
        cursor: pointer !important;
    }

    .selectable:hover {
        background-color: #F2F2F2 !important;
    }
.not-selectable {
    cursor: not-allowed !important;
}
    .not-selectable > div > label {
        cursor: not-allowed !important;
    }

.detail-table-hover:hover {
    background-color: #F2F2F2 !important;
}






