:root {
    --primary: #025EFF;
    --primary-emphasis: rgba(2, 94, 255, 0.20);
    --secondary: #898989;
    --danger: #E81123;
    --danger-emphasis: rgba(232, 17, 35, 0.2);
    --success: #008232;
    --success-emphasis: rgba(95, 210, 85, 0.2);
    --info: #0F7AFF;
    --light: #DADADA;
    --light-hover: #f2f2f2;
    --dark: #333333;
    --orange: #FF6600;
    --green: #3F989C;
    --disabled: #BDBDBD;
    --purple: #8201AB;
    --yellow: #FFCA00;
    --gray:#898989;
    --gray-light:#BDBDBD;
    --infoYellow: #FFF4CE;
    --pink:#FD397A;
}
